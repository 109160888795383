<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="mb-20">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 30px; margin-top: 30px">

                <a-col :span="24" :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="col-form">
                    <a-card class="p-0 m-0 pb-0">
                        <a-row :gutter="24" class="pt-15 pb-20">
                            <a-col :span="24" class="text-center">
                                <h5>Invitation to Join Institution</h5>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24">
                            <a-col :span="24">
                                <p>📖 &nbsp; Name: <strong>{{ institution.name }}</strong></p>
                            </a-col>

                            <a-col :span="24">
                                <p>📚 &nbsp; Type: <strong>{{ institution.institutionType }}</strong></p>
                            </a-col>


                            <a-col :span="24">
                                <p>📚 &nbsp; Phone number: <strong>{{ institution.phoneNumber }}</strong></p>
                            </a-col>

                            
                            <a-col :span="24">
                                <p>🧩 &nbsp; Email address: <strong>{{ institution.emailAddress }}</strong></p>
                            </a-col>

                            <a-col :span="24">
                                <p>🏆 &nbsp; Members: <strong>{{ institution.tutors + institution.students + institution.admins }}</strong></p>
                            </a-col>

                            <!-- <a-col :span="24">
                                <p>♾️ &nbsp; Lifetime Access</p>
                            </a-col>

                            <a-col :span="24">
                                <p>👣 &nbsp; Flexible Schedule</p>
                            </a-col>

                            <a-col :span="24" class="py-10">
                                <hr style="border: 0.8px solid #ECEBEB;"/>
                            </a-col>

                            <a-col :span="24">
                                <p>🗓 &nbsp; Begins 2nd of Feb to 16 Feb</p>
                            </a-col>

                            <a-col :span="24">
                                <p>👤 &nbsp; 20 Slots remaining</p>
                            </a-col> -->

                            <a-col :span="24" class="pt-10 pb-20">
                                <a-button id="joinBtn" type="primary" block :loading="joinInstitution.loading" @click="handleJoinInstitution" style="">
                                    Join Institution
                                </a-button>
                            </a-col>
                            
                        </a-row>

                    </a-card>
                </a-col>

            </a-row>
                                
		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                favoriteLoading: false,
                activeTab: 1,
				classes: [],
                institution: {
                    uuid: null,
                    name: null,
                    institutionType: null,
                    emailAddress: null,
                    phoneNumber: null,
                    location: null,
                    country: null,
                    educationSystems: null,
                    educationLevels: null,
                    subjects: null,
                    admins: null,
                    students: null,
                    tutors: null,
                    creator: null,
                    createdAt: null,
                    updatedAt: null,
                    verifyMembers: null,
                },
                

                joinInstitution: {
                    loading: false,
                },

                topics: [],

                userDetails: {},

				currentPage: 1,

      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Invitation', url: '/classes', isActive: false },
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getUserDetails();
			this.getInstitutionDetails();
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            formatFetures(name) {
				let formattedName = name.replace(/_/g, ' ');
                let formattedName1 = formattedName.includes('mode') ? formattedName.substring(5) : formattedName;
                return formattedName1[0].toUpperCase() + formattedName1.substring(1);
            },


            async getUserDetails() {

				let userInfo = await localStorage.getItem("user_details")

				if(userInfo != null) {
                    this.userDetails = JSON.parse(userInfo);
				}
			},

			async getInstitutionDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/institutions?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.institution.uuid = response.data.data.uuid;
                            this.institution.name = response.data.data.name;
                            this.institution.institutionType = response.data.data.institutionType;
                            this.institution.emailAddress = response.data.data.emailAddress;
                            this.institution.phoneNumber = response.data.data.phoneNumber;
                            this.institution.location = response.data.data.location;
                            this.institution.country = response.data.data.country;
                            this.institution.educationSystems = response.data.data.educationSystems;
                            this.institution.educationLevels = response.data.data.educationLevels;
                            this.institution.subjects = response.data.data.subjects;
                            this.institution.tutors = response.data.data.totalTutors;
                            this.institution.admins = response.data.data.totalAdmins;
                            this.institution.students = response.data.data.totalStudents;
                            this.institution.creator = response.data.data.creator;
                            this.institution.createdAt = response.data.data.createdAt;
                            this.institution.updatedAt = response.data.data.updatedAt;
                            this.institution.verifyMembers = response.data.data.verifyMembers;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },



            async handleJoinInstitution() {

                const userInfo = await localStorage.getItem('user_details')

                let userDetails = JSON.parse(userInfo);

                this.joinInstitution.loading = true;

                let url = `${this.$BACKEND_URL}/institutions/${this.$route.params.uuid}/member`;

                this.$AXIOS.post(url, {uuid: userDetails.uuid, isTutor: true}).then(async(response) => {

                    this.notify('You have successfully joined the institution', 'success')

                    this.joinInstitution.loading = false;

                    this.$router.push(`/home`)

                }).catch(async(error) => {

                    if(error.response != null  && error.response.data != null && error.response.data.message.includes('exists')) {
                        this.notify('You have successfully joined the institution', 'success')
                        this.$router.push(`/sign-in`);
                    }else {
                    
                        this.joinInstitution.loading = false;

                        this.notify(error.response != null  && error.response.data != null ? error.response.data.message : "Connection error", 'error');
                    
                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
                            await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    }
                });
        
            },


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}

#shareBtn, #shareBtn:hover {
    background: #2AA81A;
    color: #FFFFFF !important;
    border: none !important;
}

#wishBtn, #wishBtn:hover {
    background: #F1EFFA;
    color: #54398D !important;
    border: none !important;
}

#joinBtn, #joinBtn:hover {
    background: #F3F2F4 !important;
    color: #54398D !important;
    border: none !important;
}
</style>
